import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item,
  index,
  rechargeRequestActionHandler,
  setRequestData,
  setVisibleModal
}) => {

  return (
    <div className={styles.row} onClick={() => {
      setRequestData(item);
      setVisibleModal(true);
    }}>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.amount, 2)}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.cashrecharge_fee?.cashrecharge_fee, 2)}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.total_amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.users[0]?.uid}
      </div>
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div> :
            item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Approved
              </div> :
              item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Declined
                </div> : ""
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
      {item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
        <div className={styles.col}>
          <>
            <button
              className={cn("button-small", styles.acceptButton)}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                rechargeRequestActionHandler(item?.id, parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED), item?.unique_key, item?.amount, item?.user_id, item?.cashrecharge_fee?.cashrecharge_fee);
              }}
            >
              Approve
            </button>
            <button
              className={cn("button-small", styles.rejectButton)}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                rechargeRequestActionHandler(item?.id, parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT), item?.unique_key, item?.amount, item?.user_id, item?.cashrecharge_fee?.cashrecharge_fee);
              }}
            >
              Decline
            </button>
          </>
        </div>
        :
        <div className={styles.col}>
          NA
        </div>
      }
    </div>
  );
};

export default Row;
