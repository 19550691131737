import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const Entry = ({
  email,
  setEmail,
  forgotPasswordHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  return (
    <div className={styles.entry}>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.body}>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Email"
            required
            icon="mail"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("email")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("email", email, "required|email")}
          </span>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              forgotPasswordHandler(email);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            };
          }
          }>
            Continue
          </button>
        </div>
      </form>
    </div >
  );
};

export default Entry;
