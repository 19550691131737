import React from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Card from "../../../components/Card";

const Form = ({
  className,
  directorStatus
}) => {

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      <div className={styles.kycBox}>
        <div className={styles.statusText}>
          {
            parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ?
              "KYC is Pending"
              :
              parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ?
                "KYC Under Process"
                :
                parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ?
                  "KYC is Declined"
                  :
                  parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ?
                    "KYC is Completed"
                    :
                    ""
          }
        </div>
        <div className={styles.processMessage}>
          {
            parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_PENDING) ?
              "Director's KYC is Pending."
              :
              parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ?
                "Director's KYC is currently under Process."
                :
                parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED) ?
                  "Director's KYC is Declined."
                  :
                  parseInt(directorStatus) === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ?
                    "Director's KYC is Completed."
                    :
                    ""
          }
        </div>
      </div>
    </Card>
  );
};

export default Form;
