import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item,
  setData,
  setDetailsModal,
}) => {

  return (
    <div className={styles.row} onClick={() => {
      setData(item);
      setDetailsModal(true);
    }}>
      <div className={styles.col}>
        {item?.to_user?.uid}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.amount, 2)}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.wallet_transaction_fees?.cashrecharge_fee, 2)}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.total_amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.txId}
      </div>
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div> :
            item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Approved
              </div> :
              item?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Declined
                </div> : ""
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;
