import React, { useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";

const Promote = () => {

  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
        />
      </div>
    </>
  );
};

export default Promote;
