import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import TextInput from "../../../components/TextInput";
import LoaderScreen from "../../../components/LoaderScreen";
import SimpleReactValidator from 'simple-react-validator';
import Card from "../../../components/Card";

const Form = ({
  className,
  loading,
  updateDirectorDetailsHandler,
  firstName,
  lastName,
  setFirstName,
  setLastName
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="First name"
            name="fname"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Please enter first name"
            required
            onBlur={() => simpleValidator.current.showMessageFor("fname")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("first name", firstName, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Last name"
            name="lname"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Please enter last name"
            required
            onBlur={() => simpleValidator.current.showMessageFor("lname")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("last name", lastName, "required")}
          </span>
        </div>
      </div>
      <button className={cn("button", styles.button)} onClick={(e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
          updateDirectorDetailsHandler(firstName, lastName);
        } else {
          simpleValidator.current.showMessages();
          forceUpdate(1);
        }
      }
      }>
        <span>Verify Director</span>
      </button>
    </Card>
  );
};

export default Form;
