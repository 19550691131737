import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";
import CustomDropdown from "../../../components/CustomDropdown";
import { addNotification } from "../../../components/Notification";

const Entry = ({ onConfirm,
  email,
  setEmail,
  password,
  setPassword,
  contactNumber,
  setContactNumber,
  companyName,
  setCompanyName,
  userDataHandler,
  countryCode,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  categoryData,
  categoryText,
  setCategoryText,
  categoryValue,
  setCategoryValue,
  signUpHandler
}) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
        <TextInput
          className={styles.field}
          name="fName"
          type="text"
          placeholder="Director First Name"
          required
          icon="mail"
          value={firstName}
          onChange={(e) => { setFirstName(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("fName")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("firstName", firstName, "required")}
        </span>
        <TextInput
          className={styles.field}
          name="lName"
          type="text"
          placeholder="Director Last Name"
          required
          icon="mail"
          value={lastName}
          onChange={(e) => { setLastName(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("lName")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("lastName", lastName, "required")}
        </span>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Email"
          required
          icon="mail"
          value={email}
          onChange={(e) => { setEmail(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("email")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("email", email, "required|email")}
        </span>
        {/* <TextInput
          className={styles.field}
          name="password"
          type={passwordType}
          placeholder="Password"
          required
          icon="lock"
          view
          value={password}
          togglePassword={togglePassword}
          onChange={(e) => { setPassword(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("password")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("password", password, "required|min:9")}
        </span> */}
        <TextInput
          className={styles.field}
          name="companyName"
          type="text"
          placeholder="Business Name"
          required
          icon="store"
          value={companyName}
          onChange={(e) => { setCompanyName(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("companyName")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("business name", companyName, "required")}
        </span>
        <TextInput
          className={styles.field}
          name="countryCode"
          type="text"
          placeholder="Country Number"
          required
          icon="mobile"
          disabled={true}
          value={countryCode}
        />
        <TextInput
          className={styles.field}
          name="contactNumber"
          type="text"
          placeholder="Contact Number"
          required
          icon="mobile"
          value={contactNumber}
          onChange={(e) => { setContactNumber(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("contactNumber")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("contactNumber", contactNumber, "required")}
        </span>
        <CustomDropdown
          label={"Category"}
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={categoryValue}
          setValue={setCategoryValue}
          text={categoryText}
          setText={setCategoryText}
          options={categoryData}
        />
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (categoryText !== "Select a value") {
              // userDataHandler(firstName, lastName, email, password, companyName, countryCode, contactNumber, categoryValue);
              signUpHandler(firstName, lastName, email, companyName, contactNumber, categoryValue, countryCode);
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please select category",
                type: "danger",
              });
            };
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          };
        }
        }>
          Continue
        </button>
        {/* <div className={styles.note}>
          This site is protected by CAPTCHA.
        </div> */}
      </div>
    </div >
  );
};

export default Entry;
