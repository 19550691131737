import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import TextInput from "../../../components/TextInput";
import LoaderScreen from "../../../components/LoaderScreen";
import SimpleReactValidator from 'simple-react-validator';
import Card from "../../../components/Card";
import { addNotification } from "../../../components/Notification";
import requestHandler from "../../../actions/httpClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const Form = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [merchantId, setMerchantId] = useState("");
  const history = useHistory();
  const { userStatus } = useSelector((state) => { return state.getUserData });

  useEffect(() => {
    if (userStatus && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)) {
      history.push("/kyb-verification");
    }
  }, [userStatus]);

  const cashRechargeHandler = async (merchantId, amount) => {
    setLoading(true);
    let data = {
      user_id: localStorage.getItem("id"),
      amount: amount,
      unique_key: merchantId,
    };
    try {
      const cashRechargePayload = await requestHandler("cashrecharge-request", "post", data, "jwt_token");
      setLoading(false);
      if (cashRechargePayload && cashRechargePayload.status === 200) {
        addNotification({
          title: "Success",
          message: cashRechargePayload?.data?.message,
          type: "success",
        });
      }
      history.push("/transactions");
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Cash Recharge"
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.field}>
          <TextInput
            className={styles.className}
            label="Merchant Id"
            name="merchantId"
            placeholder='Please enter a value'
            value={merchantId}
            type="text"
            onChange={(e) => setMerchantId(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("merchantId")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("merchantId", merchantId, "required")}
          </span>
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.className}
            label="Amount"
            name="amount"
            placeholder='Please enter a value'
            value={amount}
            type="text"
            onChange={(e) => {
              const decimalRegex = /^\d*\.?\d*$/;
              if (decimalRegex.test(e.target.value)) {
                setAmount(e.target.value)
              }
            }
            }
            required
            onBlur={() => simpleValidator.current.showMessageFor("amount")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("amount", amount, "required")}
          </span>
        </div>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (parseFloat(amount) <= 0.01) {
              addNotification({
                title: "Alert",
                message: "Amount must be greater than 0.01",
                type: "danger",
              })
            } else {
              cashRechargeHandler(merchantId, amount);
            }
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }
        }>
          <span>Submit</span>
        </button>
      </form>
    </Card >
  );
};

export default Form;
