import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import LogIn from "./screens/LogIn";
import ChangePassword from "./screens/ChangePassword";
import Users from "./screens/Users";
import Merchants from "./screens/Merchants";
import UserProfile from "./screens/UserProfile";
import MerchantProfile from "./screens/MerchantProfile";
import CashRecharge from "./screens/CashRecharge";
import ReceivedTransactions from "./screens/ReceivedTransactions";
import DepositTransactions from "./screens/DepositTransactions";
import WithdrawTransactions from "./screens/WithdrawTransactions";
import KYBVerification from "./screens/KYBVerification";
import CashRechargeRequests from "./screens/CashRechargeRequests";
import ForgotPassword from "./screens/ForgotPassword";
import AccountSettings from "./screens/AccountSettings";
import TransactionsDetails from "./screens/TransactionsDetails";
import BankDetails from "./screens/BankDetails";
import BankRecharge from "./screens/BankRecharge";
import CashRechargeMerchantToMerchant from "./screens/CashRechargeMerchantToMerchant";
import { ProtectedRoutes } from "../src/components/helper"
import { useEffect } from "react";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { addNotification } from "./components/Notification";

function App() {

  // const requestForTokenn = () => {
  //   return getToken(getMessaging(), { vapidKey: "BEEd9iwhoRB0sL9uywhcEqp6gHfKgUlKLZJoBsXIZpf2TH_RDEx_w_mTtni1X-T7LrfPNOMQAtFjPoPm37DV1Dc" })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         localStorage.setItem("fcmToken", currentToken);
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //     });
  // };

  // const onMessageListener = () =>
  //   new Promise((resolve) => {
  //     onMessage(getMessaging(), (payload) => {
  //       addNotification({
  //         title: payload?.notification?.title,
  //         message: payload?.notification?.body,
  //         type: "success"
  //       });
  //       resolve(payload);
  //     });
  //   });

  // useEffect(() => {
  //   initializeApp({
  //     apiKey: "AIzaSyBZ8BIIo1gvArfo7lYtJspAkX5Q0z0aYc8",
  //     authDomain: "suripay-4750b.firebaseapp.com",
  //     projectId: "suripay-4750b",
  //     storageBucket: "suripay-4750b.appspot.com",
  //     messagingSenderId: "865011898686",
  //     appId: "1:865011898686:web:0a757aedd76e67f883746f",
  //     measurementId: "G-75H4MD0MH3"
  //   });
  //   setTimeout(() => {
  //     requestForTokenn();
  //     onMessageListener();
  //   }, 2000);
  // }, []);

  return (
    <Router>
      <Switch>
        {/* <Route
          exact
          path="/"
          render={() => (
            <Page title="Dashboard">
              <Home />
            </Page>
          )}
        /> */}
        <ProtectedRoutes
          exact
          path="/"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Dashboard">
              <Home />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/bank-details"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Bank Details">
              <BankDetails />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/add-money/bank-recharge"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Bank Recharge">
              <BankRecharge />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/add-money/merchant-recharge"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Cash Recharge Your Account">
              <CashRechargeMerchantToMerchant />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/change-password"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Change Password">
              <ChangePassword />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/kyb-verification"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="KYB Verification">
              <KYBVerification />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/users"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Users">
              <Users />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/user-profile/:id"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="User Profile">
              <UserProfile />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/merchants"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Merchants">
              <Merchants />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Transactions">
              <TransactionsDetails />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/cash-recharge/requests"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Cash Recharge Request by Customers">
              <CashRechargeRequests />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/cash-recharge/overview"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Cash Recharge Overview">
              <CashRecharge />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/received-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Received Transactions">
              <ReceivedTransactions />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/deposit-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Deposit Transactions">
              <DepositTransactions />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/withdraw-transactions"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Withdraw Transactions">
              <WithdrawTransactions />
            </Page>
          )}
        />
        <ProtectedRoutes
          exact
          path="/merchant-profile/:id"
          isAuth={localStorage.getItem('token')}
          component={() => (
            <Page title="Merchant Profile">
              <MerchantProfile />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/dashboard"
          render={() => (
            <Page title="Products dashboard">
              <ProductsDashboard />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/add"
          render={() => (
            <Page title="New product">
              <NewProduct />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/drafts"
          render={() => (
            <Page title="Drafts">
              <Drafts />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/released"
          render={() => (
            <Page title="Released">
              <Released />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/comments"
          render={() => (
            <Page title="Comments">
              <Comments />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/scheduled"
          render={() => (
            <Page title="Scheduled">
              <Scheduled />
            </Page>
          )}
        />
        <Route
          exact
          path="/customers/overview"
          render={() => (
            <Page title="Customers">
              <Customers />
            </Page>
          )}
        />
        <Route
          exact
          path="/customers/customer-list"
          render={() => (
            <Page title="Customer list">
              <CustomerList />
            </Page>
          )}
        />
        <Route
          exact
          path="/shop"
          render={() => (
            <Page wide>
              <Shop />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/earning"
          render={() => (
            <Page title="Earning">
              <Earning />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/refunds"
          render={() => (
            <Page title="Refunds">
              <Refunds />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/payouts"
          render={() => (
            <Page title="Payouts">
              <Payouts />
            </Page>
          )}
        />
        <Route
          exact
          path="/income/statements"
          render={() => (
            <Page title="Statements">
              <Statements />
            </Page>
          )}
        />
        <Route
          exact
          path="/promote"
          render={() => (
            <Page title="Promote">
              <Promote />
            </Page>
          )}
        />
        <Route
          exact
          path="/notification"
          render={() => (
            <Page title="Notification">
              <Notification />
            </Page>
          )}
        />
        <Route
          exact
          path="/edit-profile"
          render={() => (
            <Page title="Edit Profile">
              <AccountSettings />
            </Page>
          )}
        />
        <Route
          exact
          path="/settings"
          render={() => (
            <Page title="Settings">
              <Settings />
            </Page>
          )}
        />
        <Route
          exact
          path="/upgrade-to-pro"
          render={() => (
            <Page title="Upgrade to Pro">
              <UpgradeToPro />
            </Page>
          )}
        />
        <Route
          exact
          path="/message-center"
          render={() => (
            <Page title="Message center">
              <MessageCenter />
            </Page>
          )}
        />
        <Route
          exact
          path="/explore-creators"
          render={() => (
            <Page title="Explore creators">
              <ExploreCreators />
            </Page>
          )}
        />
        <Route
          exact
          path="/affiliate-center"
          render={() => (
            <Page title="Affiliate center">
              <AffiliateCenter />
            </Page>
          )}
        />
        <Route exact path="/sign-in" render={() => <LogIn />} />
        <Route exact path="/sign-up" render={() => <SignUp />} />
        <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
        {/* <Route exact path="/sign-in" render={() => <SignIn />} /> */}
        <Route exact path="/pagelist" component={PageList} />
      </Switch>
    </Router>
  );
}

export default App;
