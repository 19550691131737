import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const NewPost = ({
  data
}) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Request Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Name
        </div>
        <div className={styles.col}>
          {data?.to_user?.full_name}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Amount
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(data?.amount, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Fee</div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(data?.wallet_transaction_fees?.cashrecharge_fee, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Amount Received</div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(data?.total_amount, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          <div className={styles.col}>
            {
              data?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Pending
                </div> :
                data?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED) ?
                  <div className={cn("status-green-dark", styles.distribution)}>
                    Approved
                  </div> :
                  data?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT) ?
                    <div className={cn("status-red-dark", styles.distribution)}>
                      Declined
                    </div> : ""
            }
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>UId</div>
        <div className={styles.col}>
          {data?.to_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(data?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;
