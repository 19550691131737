import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notification.module.sass";
import Icon from "../../Icon";
import { QRCode } from 'react-qrcode-logo';

const Notification = ({ className, userStatus }) => {
  const [visible, setVisible] = useState(false);

  let scannerData = {
    suriPayId: userStatus?.uid,
    firstName: userStatus?.first_name,
    lastName: userStatus?.last_name,
    type: 0,
    displayedAmount: 0
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, styles.active)}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="barcode" size="24" />
        </button>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>QR Code</div>
          </div>
          <QRCode
            value={JSON.stringify(scannerData)}
            logoWidth={40}
            logoHeight={40}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Notification;
