import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  loading,
  setVisibleModal,
  visibleModal,
  addNewBankHandler,
  bankData,
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Banks"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              Add New Bank
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Proof</div>
            <div className={styles.col}>Bank Name</div>
            <div className={styles.col}>Account Number</div>
            <div className={styles.col}>Status</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={4} rowCount={12} />
            : bankData?.map((x, index) => (
              <Row
                item={x}
                key={index}
                index={index}
              />
            ))}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          loading={loading}
          addNewBankHandler={addNewBankHandler}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
