import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import LoaderScreen from "../../../../components/LoaderScreen";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const NewPost = ({
  addNewBankHandler,
  loading,
}) => {

  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bankProof, setBankProof] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>New Bank</div>
      </div>
      <TextInput
        className={styles.field}
        label="Account Number"
        name="accountNumber"
        type="text"
        placeholder="Please enter a value"
        required
        onChange={(e) => {
          setAccountNumber(e.target.value);
        }}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("accountNumber", accountNumber, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Account Name"
        name="accountNumber"
        type="text"
        onChange={(e) => { setAccountName(e.target.value) }}
        required
        placeholder="Please enter a value"
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("accountName", accountName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Bank Proof"
        name="bankProof"
        type="file"
        onChange={(e) => { setBankProof(e.target.files[0]) }}
        placeholder="Please enter a value"
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            addNewBankHandler(accountNumber, accountName, bankProof);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Submit</span>
      </button>
    </div>
  );
};

export default NewPost;
