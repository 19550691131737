import React, { useRef, useState } from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import LoaderScreen from "../../../../components/LoaderScreen";
import TextInput from "../../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const NewPost = ({
  cashRechargeHandler,
  loading,
}) => {

  const [customerId, setCustomerId] = useState("");
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>New Recharge</div>
      </div>
      <TextInput
        className={styles.field}
        label="Customer Id"
        name="customerId"
        type="text"
        placeholder="Please enter a value"
        required
        onChange={(e) => {
          setCustomerId(e.target.value);
        }}
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("customerId", customerId, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Amount"
        name="amount"
        type="text"
        onChange={(e) => { setAmount(e.target.value) }}
        required
        placeholder="Please enter a value"
      />
      <span className={styles.errorMessage}>
        {simpleValidator.current.message("amount", amount, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Message(Optional)"
        name="message"
        type="text"
        value={message}
        onChange={(e) => { setMessage(e.target.value) }}
        placeholder="Please enter a value"
      />
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
            cashRechargeHandler(customerId, amount, message);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Continue</span>
      </button>
    </div>
  );
};

export default NewPost;
