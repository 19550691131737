import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from 'simple-react-validator';
import { Link } from "react-router-dom/cjs/react-router-dom";

const Entry = ({
  setActiveIndex,
  captchaField,
  setCaptchaField,
  captchaData,
  captchaLoading,
  loginHandler,
  setEmailField
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [email, setEmail] = useState("")
  const [, forceUpdate] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("")

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  const base64data = btoa(unescape(encodeURIComponent(captchaData?.buffer)));

  return (
    <div className={styles.entry}>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.body}>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Email"
            required
            icon="mail"
            onChange={(e) => { setEmail(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("email")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("email", email, "required|email")}
          </span>
          {/* <TextInput
            className={styles.field}
            name="password"
            type={passwordType}
            placeholder="Password"
            required
            icon="lock"
            view
            togglePassword={togglePassword}
            onChange={(e) => { setPassword(e.target.value) }}
            onBlur={() => simpleValidator.current.showMessageFor("password")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("password", password, "required|min:9")}
          </span> */}
          <div className={styles.info}>
            <Link className={styles.link} to="/forgot-password">
              Forgot Password
            </Link>
          </div>
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              // loginHandler(email, password);
              loginHandler(email);
              setEmailField(email);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }}>
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default Entry;
