import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../../components/Notification";

const Code = ({ setActiveIndex,
  codeHandler,
  resendOtpHandler
}) => {

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [thirdField, setThirdField] = useState("");
  const [fourthField, setFourthField] = useState("");
  const [fifthField, setFifthField] = useState("");
  const [sixthField, setSixthField] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator())
  const [count, setCount] = useState(59);
  const [buttonVisible, setButtonVisible] = useState(false);
  const history = useHistory();

  const someFunction = () => {
    setCount(count - 1);
  };

  useEffect(() => {
    if (count >= 1) {
      setTimeout(someFunction, 1000);
    }
    else {
      setButtonVisible(true)
    };
  }, [count]);


  return (
    <div className={styles.code}>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.body}>
          <div className={styles.info}>
            We just send you a verify code. Check your inbox to get them.
          </div>
          <div className={styles.fieldset}>
            <div className={styles.field}>
              <input autoFocus
                className={styles.input}
                ref={ref1}
                maxLength="1"
                value={firstField}
                name="number1"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setFirstField(e.target.value);
                      ref2.current.focus();
                    }
                  }
                  else if (e.target.value === '') {
                    setFirstField('');
                  }
                }}
                onBlur={() => { simpleValidator.current.showMessageFor("field") }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref2}
                maxLength="1"
                value={secondField}
                className={styles.input}
                name="number2"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setSecondField(e.target.value);
                      ref3.current.focus();
                    }
                  }
                  else if (e.target.value === '') {
                    setSecondField('');
                    ref1.current.focus();
                  }
                }}
                onBlur={() => { simpleValidator.current.showMessageFor("field") }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref3}
                maxLength="1"
                value={thirdField}
                className={styles.input}
                name="number3"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setThirdField(e.target.value);
                      ref4.current.focus();
                    }
                  }
                  else if (e.target.value === '') {
                    setThirdField('');
                    ref2.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref4}
                maxLength="1"
                value={fourthField}
                className={styles.input}
                name="number4"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setFourthField(e.target.value);
                      ref5.current.focus();
                    }
                  }
                  else if (e.target.value === '') {
                    setFourthField('');
                    ref3.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref5}
                maxLength="1"
                value={fifthField}
                className={styles.input}
                name="number5"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setFifthField(e.target.value);
                      ref6.current.focus();
                    }
                  }
                  else if (e.target.value === '') {
                    setFifthField('');
                    ref4.current.focus();
                  }
                }}
              />
            </div>
            <div className={styles.field}>
              <input
                ref={ref6}
                maxLength="1"
                value={sixthField}
                className={styles.input}
                name="number6"
                type="tel"
                required
                onChange={(e) => {
                  const regex = /^[0-9]+$/;
                  if (e.target.value) {
                    if (regex.test(e.target.value)) {
                      setSixthField(e.target.value);
                    }
                  }
                  else if (e.target.value === '') {
                    setSixthField('');
                    ref5.current.focus();
                  }
                }
                }
              />
            </div>
          </div>
          <div className={styles.errorNote}>
            The code you entered is incorrect.
          </div>
          <button className={cn("button", styles.button)}
            onClick={() => {
              if (firstField !== "" && secondField !== "" && thirdField !== "" && fourthField !== "" && fifthField !== "" && sixthField !== "") {
                codeHandler(firstField, secondField, thirdField, fourthField, fifthField, sixthField);
              } else {
                addNotification({
                  title: 'Alert',
                  message: "Please enter a valid otp.",
                  type: 'danger'
                });
              }
            }
            }>
            <span>Continue</span>
          </button>
          {count >= 1 ? <div className={cn("h6", styles.title, styles.resendButton)}>Resend OTP in {count}</div> : null}
          {buttonVisible && (<button className={cn("button", styles.button)}
            onClick={() => {
              // resendOtpHandler();
              resendOtpHandler();
              setCount(59);
              setButtonVisible(false);
            }}>Resend Otp</button>)}
        </div>
      </form>
    </div>
  );
};

export default Code;
