import { init } from 'onfido-sdk-ui'
import { useEffect } from 'react'
import requestHandler from '../../actions/httpClient';
import styles from "./Onfido.module.sass";
import { useHistory } from "react-router-dom";

const Onfido = ({ token, workFlowRunId, applicantId, completeOnFidoHandler, onFidoFunction }) => {

    const history = useHistory();

    useEffect(() => {
        init({
            token: token,
            containerId: 'onfido-mount',
            onComplete: async (data) => {
                const reqBody = {
                    applicant_id: applicantId,
                    status: parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS),
                };
                completeOnFidoHandler(reqBody);
            },
            onError: (error) => {
                const reqBody = {
                    applicant_id: applicantId,
                    status: parseInt(process.env.REACT_APP_KYC_STATUS_PENDING),
                };
                onFidoFunction(reqBody);
            },
            onUserExit: (userExit) => {
                const reqBody = {
                    applicant_id: applicantId,
                    status: parseInt(process.env.REACT_APP_KYC_STATUS_PENDING),
                }
                onFidoFunction(reqBody);
            },
            workflowRunId: workFlowRunId,
        });

    }, [token, workFlowRunId, applicantId]);

    // const yourCustomFunction = async (reqBody) => {
    //     await requestHandler('update-merchant-kyc-status', 'post', reqBody, 'jwt_token');
    // };
    // const completeOnFido = async (reqBody) => {
    //     await requestHandler('update-merchant-kyc-status', 'post', reqBody, 'jwt_token');
    //     history.push("/");
    //     // window.location.reload();
    // };

    return (
        <div className={styles.modal} id="onfido-mount" style={{ padding: 20 }}></div>
    )
}


export default Onfido