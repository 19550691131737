import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { userDataCreator } from "../../actions/getUserStatus";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Promote = () => {

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [data, setData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userStatus && userStatus.length === 0) {
      dispatch(userDataCreator());
    }
    getTransactionDataHandler();
  }, []);

  useEffect(() => {
    if (userStatus && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)) {
      history.push("/kyb-verification");
    };
  }, [userStatus]);

  const getTransactionDataHandler = async () => {
    setLoading(true);
    let data = {
      merchant_id: localStorage.getItem("id"),
    };
    try {
      const getTransactionDataPayload = await requestHandler("transactions", "post", data, "jwt_token");
      setLoading(false);
      setTransactionData(getTransactionDataPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          transactionData={transactionData}
          data={data}
          setData={setData}
          setDetailsModal={setDetailsModal}
          detailsModal={detailsModal}
        />
      </div>
    </>
  );
};

export default Promote;
