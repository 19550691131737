import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import requestHandler from "../../actions/httpClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addNotification } from "../../components/Notification";
import LoaderScreen from "../../components/LoaderScreen";
import Image from "../../components/Image";

const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const heightWindow = use100vh();
  const [activeIndex, setActiveIndex] = useState(0);
  const [captchaData, setCaptchaData] = useState([]);
  const [captchaField, setCaptchaField] = useState("");
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [merchantId, setMerchantId] = useState("");
  const [emailField, setEmailField] = useState("");

  const resendOtpHandler = async () => {
    setLoading(true);
    let data = {
      email: emailField
    };
    try {
      const resendOtpPayload = await requestHandler("regenerate-otp", "post", data, "jwt_token");
      setLoading(false);
      if (resendOtpPayload.status === 200) {
        addNotification({
          title: "Success",
          message: resendOtpPayload?.data?.message,
          type: "success"
        });
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger"
      });
    };
  };

  // const loginHandler = async (email, password) => {
  const loginHandler = async (email) => {
    setLoading(true);
    let data = {
      email: email,
      // password: password,
      role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
      // fcm_token: localStorage.getItem("fcmToken"),
      type: "web",
    };
    try {
      const loginPayload = await requestHandler("login", "post", data, "jwt_token");
      setLoading(false);
      if (loginPayload && loginPayload.status === 200) {
        setMerchantId(loginPayload?.data?.user?.id);
        addNotification({
          title: "Success",
          message: loginPayload?.data?.message,
          type: "success",
        });
        setActiveIndex(1);
      };
      // if (loginPayload && loginPayload.status === 200) {
      //   addNotification({
      //     title: "Success",
      //     message: loginPayload?.data?.message,
      //     type: "success",
      //   });
      //   localStorage.setItem("token", loginPayload?.data?.token);
      //   localStorage.setItem("id", loginPayload?.data?.user?.id);
      //   history.push("/");
      // }
      // else if (loginPayload && loginPayload.status === 201) {
      //   setMerchantId(loginPayload?.data?.merchant_id);
      //   addNotification({
      //     title: "Success",
      //     message: loginPayload?.data?.error,
      //     type: "success",
      //   });
      //   setActiveIndex(1);
      // };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const codeHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setLoading(true);
    let data = {
      otp: firstField + secondField + thirdField + fourthField + fifthField + sixthField,
      user_id: merchantId,
    };
    try {
      const codePayload = await requestHandler("verify-otp", "post", data, "jwt_token");
      setLoading(false);
      if (codePayload.status === 200) {
        addNotification({
          title: "Success",
          message: codePayload?.data?.message,
          type: "success",
        });
        localStorage.setItem("token", codePayload?.data?.token);
        localStorage.setItem("id", codePayload?.data?.user?.id);
        history.push("/");
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={styles.preview}>
            {/* <img
              src="/images/logo-light.svg"
              srcDark="/images/logo-dark.svg"
              alt="Login" /> */}
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcDark="/images/logo-dark.svg"
              alt="Core"
            />
          </div>
          {/* <div className={cn("h4", styles.subtitle)}>Business</div> */}
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <div className={styles.info}>
            New member?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div>
        </div>
        {loading && <LoaderScreen />}
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Sign In</div>
          {activeIndex === 0 && <Entry
            setActiveIndex={setActiveIndex}
            loginHandler={loginHandler}
            captchaData={captchaData}
            captchaField={captchaField}
            setCaptchaField={setCaptchaField}
            captchaLoading={captchaLoading}
            setEmailField={setEmailField}
          />}
          {/* {activeIndex === 1 && <Barcode />} */}
          {/* {activeIndex === 1 && <Barcode />} */}
          {activeIndex === 1 && <Code
            codeHandler={codeHandler}
            resendOtpHandler={resendOtpHandler}
          />}
          {/* {activeIndex === 3 && <Confirm />} */}
          {/* {activeIndex === 3 && <Confirm />} */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
