import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import PopularProducts from "../../components/PopularProducts";
import LoaderScreen from "../../components/LoaderScreen";
import Comments from "./Comments";
import RefundRequests from "../../components/RefundRequests";
import ProTips from "./ProTips";
import MoreCustomers from "./MoreCustomers";
import ProductViews from "./ProductViews";
import { useDispatch, useSelector } from "react-redux"
import { userDataCreator } from "../../actions/getUserStatus"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import requestHandler from "../../actions/httpClient";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const [loading, setLoading] = useState(false);
  const [allStatData, setAllStatData] = useState([]);

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userDataCreator());
    }

    getDashboardCountHandler();
  }, []);

  useEffect(() => {
    if (userStatus && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)) {
      history.push("/kyb-verification");
    }
  }, [userStatus]);

  const getDashboardCountHandler = async () => {
    setLoading(true);
    let data = {
      merchant_id: localStorage.getItem("id"),
    };
    try {
      const getDashboardCountPayload = await requestHandler("dashboard-count", "post", data, "jwt_token");
      setLoading(false);
      setAllStatData(getDashboardCountPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  return (
    <>
      <div className={styles.row}>
        {loading && <LoaderScreen />}
        <div className={styles.col}>
          <Overview
            className={styles.card}
            userStatus={userStatus}
            allStatData={allStatData}
          />
          <ProductViews className={styles.card} />
          <ProTips className={styles.card} />
          <MoreCustomers />
        </div>
        <div className={styles.col}>
          <PopularProducts className={styles.card} views="4" />
          <Comments className={styles.card} />
          <RefundRequests title="Refund requests" classTitle="title-red" />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
