import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item,
  index,
}) => {

  return (
    <div className={styles.row} >
      <div className={styles.col}>
        {index + 1}
      </div>
      <div className={styles.col} onClick={() => { window.open(item?.bank_proof, '_blank', 'noopener,noreferrer') }}>
        <img className={styles.imgClass} src={item?.bank_proof} alt="proof" />
      </div>
      <div className={styles.col}>
        {item?.bank_name}
      </div>
      <div className={styles.col}>
        {item?.account_number}
      </div>
      <div className={styles.col}>
        {
          item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div> :
            item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_REJECT) ?
              <div className={cn("status-red-dark", styles.distribution)}>
                Rejected
              </div> :
              item?.status === parseInt(process.env.REACT_APP_BANK_STATUS_ACCEPT) ?
                <div className={cn("status-green-dark", styles.distribution)}>
                  Accepted
                </div> : ""
        }
      </div>
    </div>
  );
};

export default Row;
