import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Login from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { userDataCreator } from "../../actions/getUserStatus";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoaderScreen from "../../components/LoaderScreen";

const Settings = () => {
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userDataCreator());
    }
  }, []);

  const updateProfileHandler = async (businessName, address1, address2, city, state, country, postalCode) => {
    setLoading(true);
    let data = {
      address1: address1,
      address2: address2,
      state: state,
      city: city,
      country: country,
      pincode: postalCode,
      // first_name: firstName,
      // last_name: lastName,
      business_name: businessName,
      merchant_id: localStorage.getItem("id"),
    };
    try {
      const updateProfilePayload = await requestHandler("edit-profile", "post", data, "jwt_token");
      setLoading(false);
      if (updateProfilePayload && updateProfilePayload.status === 200) {
        addNotification({
          title: "Success",
          message: updateProfilePayload?.data?.message,
          type: "success",
        });
      };
      dispatch(userDataCreator());
      history.push("/");
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "danger"
      });
    };
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.wrapper}>
          {loading && <LoaderScreen />}
          <div className={cn(styles.item)}>
            <Login
              userStatus={userStatus}
              updateProfileHandler={updateProfileHandler}
            />
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Settings;
