import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import CashRechargeDetails from "./CashRechargeDetails";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  loading,
  setVisibleModal,
  visibleModal,
  cashRechargeHandler,
  cashRechargeData,
  setData,
  data,
  detailsModal,
  setDetailsModal
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Recharge"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              New Recharge
            </button>
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>UId</div>
            <div className={styles.col}>Total Amount</div>
            <div className={styles.col}>Fee</div>
            <div className={styles.col}>Amount Sent</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={7} rowCount={12} />
            :
            cashRechargeData?.length !== 0 ?
              cashRechargeData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  setData={setData}
                  setDetailsModal={setDetailsModal}
                />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          loading={loading}
          cashRechargeHandler={cashRechargeHandler}
        />
      </Modal>
      <Modal
        outerClassName={styles.outer}
        visible={detailsModal}
        onClose={() => setDetailsModal(false)}
      >
        <CashRechargeDetails
          loading={loading}
          data={data}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
