import styles from "./Create.module.sass";
import Form from './Form';

const ChangePassword = () => {

    return (
        <div className={styles.customClass}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Form />
                </div>
            </div>
        </div>
    )
};

export default ChangePassword;