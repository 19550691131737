import React, { useState } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import { posts } from "../../../mocks/posts";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const intervals = ["Pending", "Verified", "Declined"];

const RecentPost = ({ className, loading }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
        head={
          <>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            />
            {/* <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisibleModal(true)}
            >
              New post
            </button> */}
          </>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Customer Id</div>
            <div className={styles.col}>Reference Id</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={5} rowCount={12} />
            : posts.map((x, index) => (
              <Row item={x} key={index} />
            ))}
        </div>
        {/* <div className={styles.foot}>
          <button className={cn("button-stroke button-small", styles.button)}>
            <Loader className={styles.loader} />
            <span>Load more</span>
          </button>
        </div> */}
      </Card>
      {/* <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost />
      </Modal> */}
    </>
  );
};

export default RecentPost;
