import { useEffect, useState } from "react";
import requestHandler from "../../actions/httpClient";
import styles from "./Create.module.sass";
import Form from './Form';
import DirectorDetails from './DirectorDetails';
import InProcessKyc from './InProcessKyc';
import { useDispatch, useSelector } from "react-redux";
import { userDataCreator } from "../../actions/getUserStatus";
import { addNotification } from "../../components/Notification";
import Onfido from "../../components/Onfido";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { userStatus } = useSelector((state) => { return state.getUserData });
    const [activeIndex, setActiveIndex] = useState(0);
    const [token, setToken] = useState("");
    const [workFlowRunId, setWorkFlowRunId] = useState("");
    const [applicantId, setApplicantId] = useState("");
    const history = useHistory();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [directorId, setDirectorId] = useState("");
    const [directorStatus, setDirectorStatus] = useState("");

    useEffect(() => {
        if (userStatus && userStatus.length === 0) {
            dispatch(userDataCreator());
        }
    }, []);

    useEffect(() => {
        userStatus && userStatus?.director?.map((x) => {
            setFirstName(x?.director_first_name);
            setLastName(x?.director_last_name);
            setDirectorId(x?.id);
            setDirectorStatus(x?.status);
        })

        userStatus?.director?.map((x) => {
            if (parseInt(x?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_INPROCESS) ||
                parseInt(x?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED) ||
                parseInt(x?.status) === parseInt(process.env.REACT_APP_KYC_STATUS_DECLINED)) {
                setActiveIndex(3);
            }
        })

    }, [userStatus]);

    const updateOnfidoHandler = async (applicant_id) => {
        setLoading(true);
        let data = {
            applicant_id: applicant_id
        };
        try {
            const updateOnfidoPayload = await requestHandler("update-onfido-token", "post", data, "jwt_token");
            setLoading(false);
            if (updateOnfidoPayload?.status === 200) {
                setToken(updateOnfidoPayload?.data?.data?.sdk_token);
                setWorkFlowRunId(updateOnfidoPayload?.data?.data?.workflow_run_id);
                setApplicantId(updateOnfidoPayload?.data?.data?.applicant_kyc_id);
            }
        }
        catch (e) {
            setLoading(false);
        };
    };

    const checkDirectorKycHandler = async (director_id) => {
        setLoading(true);
        let data = {
            director_id: director_id
        };
        try {
            const checkDirectorKycPayload = await requestHandler("business-onfido-check", "post", data, "jwt_token");
            setLoading(false);
            updateOnfidoHandler(checkDirectorKycPayload?.data?.data?.businesskyc?.applicant_kyc_id);
        }
        catch (e) {
            setLoading(false);
        };
    };

    const kybVerificationHandler = async (address1, address2, city, state, country, pincode, addressProof, businessProof) => {
        setLoading(true);
        let data = {
            merchant_id: userStatus?.id,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            country: country,
            pincode: pincode,
            image: addressProof,
            type: "residence_proof",
            business_image: businessProof,
        };
        try {
            const kybVerificationPayload = await requestHandler("kycVerification/kyb", "post", data, "jwt_token", "file");
            setLoading(false);
            if (kybVerificationPayload && kybVerificationPayload?.status === 200) {
                dispatch(userDataCreator());
                checkDirectorKycHandler(kybVerificationPayload?.data?.data?.director[0]?.id);
                setActiveIndex(1);
            };
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: e?.data?.error,
                type: "danger",
            });
        };
    };

    const completeOnFidoHandler = async (reqBody) => {
        await requestHandler('update-merchant-kyc-status', 'post', reqBody, 'jwt_token');
        history.push("/");
        dispatch(userDataCreator());
        // window.location.reload();
    };

    const onFidoFunction = async (reqBody) => {
        await requestHandler('update-merchant-kyc-status', 'post', reqBody, 'jwt_token');
        dispatch(userDataCreator());
    };

    const updateDirectorDetailsHandler = async (firstName, lastName) => {
        setLoading(true);
        let data = {
            director_id: parseInt(directorId),
            director_first_name: firstName,
            director_last_name: lastName,
        };
        try {
            const updateDirectorPayload = await requestHandler("update-director", "post", data, "jwt_token");
            setLoading(false);
            if (updateDirectorPayload.status === 200) {
                setActiveIndex(2);
            };
        }
        catch (e) {
            setLoading(false);
        };
    };

    return (
        <div className={styles.row}>
            {activeIndex === 0 &&
                <div className={styles.col}>
                    <Form
                        loading={loading}
                        setLoading={setLoading}
                        kybVerificationHandler={kybVerificationHandler}
                        userStatus={userStatus}
                    />
                </div>
            }
            {
                activeIndex === 1 &&
                <DirectorDetails
                    firstName={firstName}
                    lastName={lastName}
                    setFirstName={setFirstName}
                    setLastName={setLastName}
                    updateDirectorDetailsHandler={updateDirectorDetailsHandler}
                />
            }
            {(activeIndex === 2) &&
                <div className={styles.col}>
                    <Onfido
                        completeOnFidoHandler={completeOnFidoHandler}
                        onFidoFunction={onFidoFunction}
                        applicantId={applicantId}
                        token={token}
                        workFlowRunId={workFlowRunId}
                    />
                </div>
            }
            {
                activeIndex === 3 &&
                <InProcessKyc directorStatus={directorStatus} />
            }
        </div>
    )
};

export default ChangePassword;