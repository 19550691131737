import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Cell from "./Cell";
import { Link } from "react-router-dom";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>
      <Link className={styles.col}
        to={{
          pathname: `/merchant-profile/${101}`,
          state: "",
        }}>
        <div className={styles.text}>
          101
        </div>
      </Link>
      <div className={styles.col}>
        12312
      </div>
      <div className={styles.col}>
        Nxtgen
      </div>
      <div className={styles.col}>
        Virtue
      </div>
      <div className={styles.col}>
        nxtgenvirtue1@gmail.com
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Activate
        </div>
      </div>
      <div className={styles.col}>
        08-Aug-2023
      </div>
    </div>
  );
};

export default Row;
