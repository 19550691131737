import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from "./CustomerListSkeleton.module.sass";

const CustomerListSkeleton = ({ rowCount, colCount }) => {
    var themeMode = localStorage.getItem('darkMode');
    return (
        Array(rowCount)
            .fill()
            .map((item, index) => (
                <div className={styles.row} key={index}>
                    {Array(colCount)
                        .fill()
                        .map((i, x) => (
                            <div className={styles.col} key={x}>
                                <Skeleton
                                    count={1}
                                    height={35}
                                    baseColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_BASE_DARK : process.env.REACT_APP_SKELETON_BASE_LIGHT}
                                    highlightColor={themeMode === "true" ? process.env.REACT_APP_SKELETON_HIGHLIGHT_DARK : process.env.REACT_APP_SKELETON_HIGHLIGHT_LIGHT}
                                />
                            </div>
                        ))
                    }
                </div>
            ))
    );
};

export default CustomerListSkeleton;
