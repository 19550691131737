import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        102
      </div>
      <div className={styles.col}>
        $10
      </div>
      <div className={styles.col}>
        1234567890
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.distribution)}>
          Success
        </div>
      </div>
      <div className={styles.col}>
        08-Aug-2023
      </div>
    </div>
  );
};

export default Row;
