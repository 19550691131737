import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import TextInput from "../../../components/TextInput";
import LoaderScreen from "../../../components/LoaderScreen";
import SimpleReactValidator from 'simple-react-validator';
import Card from "../../../components/Card";

const Form = ({
  className,
  loading,
  kybVerificationHandler,
  userStatus
}) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [, forceUpdate] = useState();
  const [address1, setAddress1] = useState(userStatus?.address?.address1);
  const [address2, setAddress2] = useState(userStatus?.address?.address2);
  const [city, setCity] = useState(userStatus?.address?.city);
  const [state, setState] = useState(userStatus?.address?.state);
  const [country, setCountry] = useState(userStatus?.address?.country);
  const [pinCode, setPinCode] = useState(userStatus?.address?.pincode);
  const [addressProof, setAddressProof] = useState();
  const [businessProof, setBusinessProof] = useState();

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Address1"
            name="address1"
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
            placeholder="Please enter address1"
            required
            onBlur={() => simpleValidator.current.showMessageFor("address1")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("address1", address1, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Address2(Optional)"
            name="type"
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            placeholder="Please enter address2"
            required
          // onBlur={() => simpleValidator.current.showMessageFor("address2")}
          />
          {/* <span className={styles.errorMessage}>
            {simpleValidator.current.message("address2", address2, "required")}
          </span> */}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="City"
            name="city"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Please enter city"
            required
            onBlur={() => simpleValidator.current.showMessageFor("city")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("city", city, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="State(Optional)"
            name="state"
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder="Please enter state"
            required
          // onBlur={() => simpleValidator.current.showMessageFor("state")}
          />
          {/* <span className={styles.errorMessage}>
            {simpleValidator.current.message("state", state, "required")}
          </span> */}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Country"
            name="country"
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Please enter country"
            required
            onBlur={() => simpleValidator.current.showMessageFor("country")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("country", country, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Postal Code(Optional)"
            name="pinCode"
            type="text"
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
            placeholder="Please enter postal code"
            required
          // onBlur={() => simpleValidator.current.showMessageFor("pinCode")}
          />
          {/* <span className={styles.errorMessage}>
            {simpleValidator.current.message("pinCode", pinCode, "required")}
          </span> */}
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Address Proof"
            name="addressProof"
            type="file"
            onChange={(e) => setAddressProof(e.target.files[0])}
            onBlur={() => simpleValidator.current.showMessageFor("addressProof")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("addressProof", addressProof, "required")}
          </span>
        </div>
        <div className={styles.customField}>
          <TextInput
            className={styles.field}
            label="Business Proof"
            name="businessProof"
            type="file"
            onChange={(e) => setBusinessProof(e.target.files[0])}
            onBlur={() => simpleValidator.current.showMessageFor("businessProof")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("businessProof", businessProof, "required")}
          </span>
        </div>
      </div>
      <button className={cn("button", styles.button)} onClick={(e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
          kybVerificationHandler(address1, address2, city, state, country, pinCode, addressProof, businessProof);
        } else {
          simpleValidator.current.showMessages();
          forceUpdate(1);
        }
      }
      }>
        <span>Submit</span>
      </button>
    </Card>
  );
};

export default Form;
