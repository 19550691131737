import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import ResetPassword from "./ResetPassword";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const heightWindow = use100vh();
  const [activeIndex, setActiveIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const history = useHistory();

  const forgotPasswordHandler = async (email) => {
    setLoading(true);
    let data = {
      email: email
    };
    try {
      const forgotPasswordPayload = await requestHandler("forgot-password", "post", data, "jwt_token");
      setLoading(false);
      if (forgotPasswordPayload && forgotPasswordPayload.status === 200) {
        addNotification({
          title: "Success",
          message: forgotPasswordPayload?.data?.message,
          type: "success",
        });
        setActiveIndex(1);
        setMerchantId(forgotPasswordPayload?.data?.user?.id);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const verifyOTPHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setLoading(true);
    let data = {
      otp: firstField + secondField + thirdField + fourthField + fifthField + sixthField,
      user_id: merchantId,
    }
    try {
      const verifyOTPPayload = await requestHandler("verify-otp", "post", data, "jwt_token");
      setLoading(false);
      if (verifyOTPPayload && verifyOTPPayload.status === 200) {
        addNotification({
          title: "Success",
          message: verifyOTPPayload?.data?.message,
          type: "success",
        });
        setActiveIndex(2);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const resetPasswordHandler = async (newPassword, confirmNewPassword) => {
    setLoading(true);
    let data = {
      email: email,
      mpin: newPassword,
      mpin_confirmation: confirmNewPassword
    };
    try {
      const resetPasswordPayload = await requestHandler("reset-password", "post", data, "jwt_token");
      setLoading(false);
      if (resetPasswordPayload && resetPasswordPayload.status === 200) {
        addNotification({
          title: "Success",
          message: resetPasswordPayload?.data?.message,
          type: "success",
        });
        history.push("/sign-in");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const resendOtpHandler = async () => {
    setLoading(true);
    let data = {
      email: email
    };
    try {
      const resendOtpPayload = await requestHandler("regenerate-otp", "post", data, "jwt_token");
      setLoading(false);
      if (resendOtpPayload.status === 200) {
        addNotification({
          title: "Success",
          message: resendOtpPayload?.data?.message,
          type: "success"
        });
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger"
      });
    };
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={styles.preview}>
            <img src="/images/logo-light.svg" alt="Login" />
          </div>
          <div className={cn("h4", styles.subtitle)}>Merchants</div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <div className={styles.info}>
            Already a member?{" "}
            <Link className={styles.link} to="/sign-in">
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Forgot Password</div>
          {loading && <LoaderScreen />}
          {activeIndex === 0 &&
            <Entry
              email={email}
              setEmail={setEmail}
              forgotPasswordHandler={forgotPasswordHandler}
            />
          }
          {activeIndex === 1 &&
            <Code
              verifyOTPHandler={verifyOTPHandler}
              resendOtpHandler={resendOtpHandler}
            />
          }
          {activeIndex === 2 &&
            <ResetPassword
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              confirmNewPassword={confirmNewPassword}
              setConfirmNewPassword={setConfirmNewPassword}
              resetPasswordHandler={resetPasswordHandler}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default SignUp;
