import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import TextInput from "../../../components/TextInput";
import LoaderScreen from "../../../components/LoaderScreen";
import CustomDropdown from "../../../components/CustomDropdown";
import SimpleReactValidator from 'simple-react-validator';
import Card from "../../../components/Card";
import { addNotification } from "../../../components/Notification";
import { userDataCreator } from "../../../actions/getUserStatus";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from "../../../actions/httpClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDigitsAfterDecimal } from "../../../components/helper";

const Form = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const dispatch = useDispatch();
  const [bankText, setBankText] = useState("Please select a value");
  const [bankValue, setBankValue] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userDataCreator());
    }
    getActiveBanksHandler();
    referenceNumberGenerator();
  }, []);

  useEffect(() => {
    if (userStatus && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)) {
      history.push("/kyb-verification");
    }
  }, [userStatus]);

  const referenceNumberGenerator = () => {
    setReferenceNumber('PAY' + Math.floor(100000 + Math.random() * 900000) + '' + Math.floor(100000 + Math.random() * 900000));
  };

  const getActiveBanksHandler = async () => {
    setLoading(true);
    let data = {
      user_id: localStorage.getItem("id"),
    };
    try {
      const getActiveBanksPayload = await requestHandler("bankDetail/active-index", "post", data, "jwt_token");
      setLoading(false);
      const newArray = getActiveBanksPayload?.data?.data?.map((x) => {
        return { "cId": x?.id, "cValue": x?.bank_name }
      });
      setBankOptions([...newArray]);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const bankRechargeHandler = async (amount, bankValue, referenceNumber) => {
    setLoading(true);
    let data = {
      user_id: localStorage.getItem("id"),
      amount: amount,
      payment_reference: referenceNumber,
      bank_id: bankValue,
    };
    try {
      const bankRechargePayload = await requestHandler("wallet/add-balance", "post", data, "jwt_token");
      setLoading(false);
      if (bankRechargePayload && bankRechargePayload.status === 200) {
        addNotification({
          title: "Success",
          message: bankRechargePayload?.data?.message,
          type: "success",
        });
      }
      history.push("/transactions");
      dispatch(userDataCreator());
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  }

  return (
    <Card
      className={cn(styles.card, className)}
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <div className={cn("h4", styles.title)}>Wallet Balance : {getDigitsAfterDecimal(userStatus?.wallet?.balance, 2)}</div>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.field}>
          <CustomDropdown
            className={styles.dropdown}
            label="Select Bank"
            text={bankText}
            setText={setBankText}
            value={bankValue}
            setValue={setBankValue}
            options={bankOptions}
          />
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.className}
            label="Amount"
            name="amount"
            placeholder='Please enter a value'
            value={amount}
            type="text"
            onChange={(e) => setAmount(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("amount")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("amount", amount, "required")}
          </span>
        </div>
        <div className={styles.field}>
          <TextInput
            className={styles.className}
            label="Reference Number"
            name="referenceNumber"
            value={referenceNumber}
            type="text"
            disabled={true}
          />
        </div>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (bankText !== "Please select a value") {
              bankRechargeHandler(amount, bankValue, referenceNumber);
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please select a bank",
                type: "danger",
              });
            }
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }
        }>
          <span>Submit</span>
        </button>
      </form>
    </Card >
  );
};

export default Form;
