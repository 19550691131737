import requestHandler from '../httpClient';
import {
    GET_USER_DATA_REQUEST,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILED
} from './actionType';


const getUserDataRequest = () => {
    return {
        type: GET_USER_DATA_REQUEST
    };
};

const getUserDataSuccess = (payload) => {
    return {
        type: GET_USER_DATA_SUCCESS,
        payload: payload
    };
};

const getUserDataFailed = (error) => {
    return {
        type: GET_USER_DATA_FAILED,
        error: error
    };
};

const userDataCreator = () => {
    return async (dispatch) => {
        dispatch(getUserDataRequest());
        try {
            let data = {
                merchant_id: localStorage.getItem("id"),
                role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
            };
            const payload = await requestHandler('merchant-profile', 'post', data, 'jwt_token');
            dispatch(getUserDataSuccess(payload?.data?.data));
        }
        catch (error) {
            dispatch(getUserDataFailed(error));
        };
    };
};

export {
    userDataCreator
};