import React, { useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { addNotification } from "../../../components/Notification";

const Login = ({ className, userStatus, updateProfileHandler }) => {

  const [firstName, setFirstName] = useState(userStatus?.first_name ? userStatus?.first_name : "");
  const [lastName, setLastName] = useState(userStatus?.last_name ? userStatus?.last_name : "");
  const [businessName, setBusinessName] = useState(userStatus?.bussiness_name ? userStatus?.bussiness_name : "");
  const [email, setEmail] = useState(userStatus?.email ? userStatus?.email : "");
  const [phoneNumber, setPhoneNumber] = useState(userStatus?.phone ? `${userStatus?.country}(${userStatus?.phone})` : "");
  const [uId, setUId] = useState(userStatus?.uid ? userStatus?.uid : "");
  const [address1, setAddress1] = useState(userStatus?.address?.address1 ? userStatus?.address?.address1 : "");
  const [address2, setAddress2] = useState(userStatus?.address?.address2 ? userStatus?.address?.address2 : "");
  const [city, setCity] = useState(userStatus?.address?.city ? userStatus?.address?.city : "");
  const [state, setState] = useState(userStatus?.address?.state ? userStatus?.address?.state : "");
  const [country, setCountry] = useState(userStatus?.address?.country ? userStatus?.address?.country : "");
  const [postalCode, setPostalCode] = useState(userStatus?.address?.pincode ? userStatus?.address?.pincode : "");

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile"
      classTitle="title-purple"
    >
      <div className={styles.fieldset}>
        {/* <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="First Name"
            name="firstName"
            type="text"
            placeholder="Please fill this field"
            required
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value) }}
          />
          <TextInput
            className={styles.field}
            label="Last Name"
            name="lastName"
            type="text"
            placeholder="Please fill this field"
            required
            value={lastName}
            onChange={(e) => { setLastName(e.target.value) }}
          />
        </div> */}
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Business Name"
            name="businessName"
            type="text"
            placeholder="Please fill this field"
            required
            value={businessName}
            onChange={(e) => { setBusinessName(e.target.value) }}
          />
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            type="email"
            placeholder="Please fill this field"
            required
            value={email}
            disabled={true}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Phone Number"
            name="phoneNumber"
            type="text"
            placeholder="Please fill this field"
            required
            value={phoneNumber}
            disabled={true}
          />
          <TextInput
            className={styles.field}
            label="UId"
            name="uid"
            type="text"
            placeholder="Please fill this field"
            required
            value={uId}
            disabled={true}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Address1"
            name="address1"
            type="text"
            placeholder="Please fill this field"
            required
            value={address1}
            onChange={(e) => { setAddress1(e.target.value) }}
          />
          <TextInput
            className={styles.field}
            label="Address2"
            name="address2"
            type="text"
            placeholder="Please fill this field"
            required
            value={address2}
            onChange={(e) => { setAddress2(e.target.value) }}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="City"
            name="city"
            type="text"
            placeholder="Please fill this field"
            required
            value={city}
            onChange={(e) => { setCity(e.target.value) }}
          />
          <TextInput
            className={styles.field}
            label="State"
            name="state"
            type="text"
            placeholder="Please fill this field"
            required
            value={state}
            onChange={(e) => { setState(e.target.value) }}
          />
        </div>
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Country"
            name="country"
            type="text"
            placeholder="Please fill this field"
            required
            value={country}
            onChange={(e) => { setCountry(e.target.value) }}
          />
          <TextInput
            className={styles.field}
            label="Postal code"
            name="postalCode"
            type="text"
            placeholder="Please fill this field"
            required
            value={postalCode}
            onChange={(e) => { setPostalCode(e.target.value) }}
          />
        </div>
        <button className={cn("button button-small", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            if (businessName !== "" && address1 !== "" && address2 !== "" && city !== "" && state !== "" && country !== "" && postalCode !== "") {
              updateProfileHandler(businessName, address1, address2, city, state, country, postalCode);
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please fill all the fields.",
                type: "danger",
              });
            }
          }}
        >
          Save Profile
        </button>
      </div>
    </Item>
  );
};

export default Login;
