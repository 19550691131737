import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const NewPost = ({
  data
}) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Transaction Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          From
        </div>
        <div className={styles.col}>
          {data?.from_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          To
        </div>
        <div className={styles.col}>
          {data?.to_user?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Amount
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(data?.amount, 2)}
        </div>
      </div>

    </div>
  );
};

export default NewPost;
