import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Promote.module.sass";
import RecentPost from "./RecentPost";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userDataCreator } from "../../actions/getUserStatus";

const Promote = () => {

  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [bankData, setBankData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userStatus && userStatus.length === 0) {
      dispatch(userDataCreator());
    }
    getAllBankHandler();
  }, []);

  useEffect(() => {
    if (userStatus && userStatus?.kyc_verification !== parseInt(process.env.REACT_APP_KYC_STATUS_COMPLETED)) {
      history.push("/kyb-verification");
    };
  }, [userStatus]);

  const addNewBankHandler = async (accountNumber, accountName, bankProof) => {
    setLoading(true);
    let data = {
      user_id: localStorage.getItem("id"),
      bank_name: accountName,
      account_number: accountNumber,
      bank_proof: bankProof,
    };
    try {
      const addNewBankPayload = await requestHandler("bankDetail/create", "post", data, "jwt_token", "file");
      setLoading(false);
      if (addNewBankPayload && addNewBankPayload.status === 200) {
        addNotification({
          title: "Success",
          message: addNewBankPayload?.data?.message,
          type: "success",
        });
        setVisibleModal(false);
        getAllBankHandler();
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const getAllBankHandler = async () => {
    setLoading(true);
    let data = {
      user_id: localStorage.getItem("id"),
    };
    try {
      const getAllBankPayload = await requestHandler("bankDetail/index", "post", data, "jwt_token");
      setLoading(false);
      setBankData(getAllBankPayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <div className={styles.section}>
        <RecentPost
          loading={loading}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          bankData={bankData}
          addNewBankHandler={addNewBankHandler}
        />
      </div>
    </>
  );
};

export default Promote;
