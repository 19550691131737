import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";

export const toFixedPrice = (price, coin) => {
    switch (coin?.toLowerCase()) {
        case 'eur':
        case 'usdt':
            return parseFloat(price).toFixed(2);
            break;
        case 'btc':
        case 'eth':
        case 'ltc':
            return parseFloat(price).toFixed(8);
            break;
        case 'trx':
        case 'xrp':
            return parseFloat(price).toFixed(5);
            break;
        default:
            return parseFloat(price).toFixed(4);
            break;
    }
}

export const REGEX = /^[]?\d*(?:[.]\d*)?$/;

export const generateString = length => {

    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        if (i % 4 == 0) {
            result += ' ';
        }
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result.toLocaleUpperCase();


}


export const getActInactCoinStatus = (status) => {
    if (status === parseInt(process.env.REACT_APP_ACTIVE_COIN)) {
        return <span style={{ color: 'green' }}>Active</span>;
    }
    return <span style={{ color: 'red' }}>InActive</span>

}

export const getActInactMarketStatus = (status) => {
    if (status === parseInt(process.env.REACT_APP_ACTIVE_MARKET)) {
        return <span style={{ color: 'green' }}>Active</span>;
    }
    return <span style={{ color: 'red' }}>InActive</span>
}

export const getDisableMarketOrders = (value) => {
    if (value.toString() === process.env.REACT_APP_DISABLE_MARKET_ORDER) {
        return <span style={{ color: 'green' }}>Enable Market Order</span>
    }
    return <span style={{ color: 'red' }}>Disable Market Order</span>
}

export const getCoinType = (value) => {
    if (value === parseInt(process.env.REACT_APP_COIN)) {
        return "Coin";
    } else if (value === parseInt(process.env.REACT_APP_ERC20)) {
        return "ERC 20";
    } else if (value === parseInt(process.env.REACT_APP_TRC20)) {
        return "TRC 20";
    }
}

const toLocaleStringDateFormat = (value) => {
    return new Date(value).toLocaleString();
};

const disableMarketOrders = (value) => {
    if (value === parseInt(process.env.REACT_APP_MARKET_ORDER_NO)) {
        return <span style={{ color: 'red' }}>No</span>;
    } else {
        return <span style={{ color: 'green' }}>Yes</span>;
    }
};

const getMarketStatus = (value) => {
    if (value === parseInt(process.env.REACT_APP_MARKET_STATUS_DISABLE)) {
        return <span style={{ color: 'red' }}>Disable</span>;
    }
    else {
        return <span style={{ color: 'green' }}>Enable</span>;
    }
};

const getDigitsAfterDecimal = (num, places) => {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}

const toFixedAfterTwo = (num) => {
    return num.toFixed(2) + '%'
}
const disableMarketOrdersWithoutStatusColor = (value) => {
    if (value === parseInt(process.env.REACT_APP_MARKET_ORDER_NO)) {
        return "No";
    } else {
        return "Yes";
    }
};

const getMarketStatusWithoutStatusColor = (value) => {
    if (value === parseInt(process.env.REACT_APP_MARKET_STATUS_DISABLE)) {
        return "Disable";
    }
    else {
        return "Enable";
    }
};

const getActInactCoinStatusWithoutStatusColor = (status) => {
    if (status === parseInt(process.env.REACT_APP_ACTIVE_COIN)) {
        return "Active";
    }
    return "InActive";

}

const ProtectedRoutes = ({ isAuth: isAuth, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={() => {
                if (localStorage.getItem('token')) {
                    return (
                        <Component />
                    )
                }
                else {
                    return (
                        <Redirect to={{ pathname: '/sign-in' }} />
                    )
                };
            }}
        />
    );
};


export {
    toLocaleStringDateFormat, disableMarketOrders, getMarketStatus, getDigitsAfterDecimal, disableMarketOrdersWithoutStatusColor,
    getMarketStatusWithoutStatusColor, getActInactCoinStatusWithoutStatusColor, toFixedAfterTwo, ProtectedRoutes
}