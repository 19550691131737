import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Row from "./Row";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";
import Modal from "../../../components/Modal";
import NewPost from "./NewPost";

const RecentPost = ({
  className,
  loading,
  cashRechargeRequestData,
  rechargeRequestActionHandler,
  setVisibleModal,
  visibleModal,
  requestData,
  setRequestData,
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Requests"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Fee</div>
            <div className={styles.col}>Amount Sent</div>
            <div className={styles.col}>UId</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Action</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            cashRechargeRequestData?.length !== 0 ?
              cashRechargeRequestData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  index={index}
                  rechargeRequestActionHandler={rechargeRequestActionHandler}
                  setRequestData={setRequestData}
                  setVisibleModal={setVisibleModal}
                />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          loading={loading}
          requestData={requestData}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
