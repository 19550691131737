import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import TextInput from "../../../components/TextInput";
import LoaderScreen from "../../../components/LoaderScreen";
import SimpleReactValidator from 'simple-react-validator';
import Card from "../../../components/Card";
import { addNotification } from "../../../components/Notification";
import { userDataCreator } from "../../../actions/getUserStatus";
import { useDispatch, useSelector } from "react-redux";
import requestHandler from "../../../actions/httpClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Form = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const { userStatus } = useSelector((state) => { return state.getUserData });
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const simpleValidator = useRef(new SimpleReactValidator(
    {
      messages: {
        in: "Password should be matched!"
      }
    }
  ))

  useEffect(() => {
    if (userStatus && userStatus.length === 0) {
      dispatch(userDataCreator());
    }
  }, []);

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  const changePasswordHandler = async (current_password, password, cnf_password) => {
    setLoading(true);
    let data = {
      current_password: current_password,
      password: password,
      password_confirmation: cnf_password,
      user_id: userStatus?.id,
    };
    try {
      const changePasswordPayload = await requestHandler("change-password", "post", data, "jwt_token");
      setLoading(false);
      if (changePasswordPayload && changePasswordPayload.status === 200) {
        addNotification({
          title: 'Success',
          message: changePasswordPayload.data.message,
          type: 'success'
        });
        history.push("/");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e.data.message,
        type: 'danger'
      });
    };
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Change Password"
      classTitle="title-green"
    >
      {loading && <LoaderScreen />}
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.field}>
          <TextInput
            className={styles.className}
            label="Current Password"
            name="currentPassword"
            placeholder='Please enter current password'
            value={currentPassword}
            type="password"
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("currentPassword")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("currentPassword", currentPassword, "required")}
          </span>
          <TextInput
            className={styles.className}
            label="New Password"
            name="newPassword"
            placeholder='Please enter new password'
            value={newPassword}
            togglePassword={togglePassword}
            type={passwordType}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            view
            onBlur={() => simpleValidator.current.showMessageFor("newPassword")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("newPassword", newPassword, "required|min:9")}
          </span>
          <TextInput
            className={styles.className}
            label="Confirm Password"
            name="confirmPassword"
            placeholder='Please enter confirm password'
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            onBlur={() => simpleValidator.current.showMessageFor("confirmPassword")}
          />
          <span className={styles.errorMessage}>
            {simpleValidator.current.message("confirmPassword", confirmPassword, `required|in:${newPassword}`)}
          </span>
          <br />
          <button className={cn("button", styles.button)} onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              changePasswordHandler(currentPassword, newPassword, confirmPassword);
            } else {
              simpleValidator.current.showMessages();
              forceUpdate(1);
            }
          }
          }>
            <span>Submit</span>
          </button>
        </div>
      </form>
    </Card >
  );
};

export default Form;
