import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../../../components/LoaderScreen";
import TextInput from "../../../../components/TextInput";

const Form = ({ loading }) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  return (
    <div className={styles.share}>
      {loading && <LoaderScreen />}
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Add New Merchant</div>
      </div>
      <TextInput
        className={styles.field}
        label="First Name"
        name="firstName"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setFirstName(e.target.value) }}
        required
      />
      <span>
        {simpleValidator.current.message("firstName", firstName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Last Name"
        name="lastName"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setLastName(e.target.value) }}
        required
      />
      <span>
        {simpleValidator.current.message("lastName", lastName, "required")}
      </span>
      <TextInput
        className={styles.field}
        label="Email"
        name="email"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setEmail(e.target.value) }}
        required
      />
      <span>
        {simpleValidator.current.message("email", email, "required|email")}
      </span>
      <TextInput
        className={styles.field}
        label="Mobile Number"
        name="mobileNumber"
        type="text"
        placeholder="Please enter a value"
        onChange={(e) => { setMobileNumber(e.target.value) }}
        required
      />
      <span>
        {simpleValidator.current.message("mobileNumber", mobileNumber, "required")}
      </span>
      <button className={cn("button button-small", styles.button)}
        onClick={(e) => {
          e.stopPropagation();
          if (simpleValidator.current.allValid()) {
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(1);
          }
        }}>
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Form;
