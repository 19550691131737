import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Barcode from "./Barcode";
import Residence from "./Residence";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import { useDispatch } from "react-redux";

const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const [visible, setVisible] = useState(true);
  const heightWindow = use100vh();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [countryCode, setCountryCode] = useState(597);
  const [userData, setUserData] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryText, setCategoryText] = useState("Select a value");
  const [categoryValue, setCategoryValue] = useState("");
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    getCategoriesDatHandler();
  }, []);

  const getCategoriesDatHandler = async () => {
    setLoading(true);
    try {
      const categoriesDataPayload = await requestHandler("category/index", "get", "", "jwt_token");
      setLoading(false);
      const newDropDownValue = categoriesDataPayload?.data?.data?.map((x) => { return { "cId": x.id, "cValue": x.name } });
      setCategoryData([...newDropDownValue]);
    }
    catch (e) {
      setLoading(false);
    }
  };

  const userDataHandler = (firstName, lastName, email, password, companyName, countryCode, contactNumber, sorting) => {
    setActiveIndex(1);
    userData.firstName = firstName
    userData.lastName = lastName
    userData.email = email
    userData.password = password
    userData.companyName = companyName
    userData.countryCode = countryCode
    userData.contactNumber = contactNumber
    userData.sorting = sorting
    setUserData(userData);
  };

  const signUpHandlerOld = async (address1, address2, city, state, country, pinCode) => {
    setLoading(true);
    setUserData(prevUserData => ({
      ...prevUserData,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: country,
      pinCode: pinCode,
    }));
    let data = {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: country,
      pincode: pinCode,
      first_name: userData.firstName,
      last_name: userData.lastName,
      bussiness_name: userData.companyName,
      email: userData.email,
      email_confirmation: userData.email,
      password: userData.password,
      password_confirmation: userData.password,
      countryCode: userData.countryCode,
      phone: userData.contactNumber,
      category: userData.sorting,
      role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
      // fcm_token: localStorage.getItem("fcmToken"),
      type: "web",
    };
    try {
      const signUpPayload = await requestHandler("register", "post", data, "jwt_token");
      setLoading(false);
      setUserId(signUpPayload?.data?.user?.id);
      if (signUpPayload.status === 200) {
        addNotification({
          title: "Success",
          message: signUpPayload?.data?.message,
          type: "success",
        });
        setActiveIndex(2);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error?.email[0],
        type: "danger",
      });
    };
  };

  const codeHandler = async (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    setLoading(true);
    let data = {
      otp: firstField + secondField + thirdField + fourthField + fifthField + sixthField,
      user_id: userId,
    };
    try {
      const codePayload = await requestHandler("verify-otp", "post", data, "jwt_token");
      setLoading(false);
      if (codePayload.status === 200) {
        addNotification({
          title: "Success",
          message: codePayload?.data?.message,
          type: "success",
        })
        // setActiveIndex(3);
        // dispatch();
        localStorage.setItem("token", codePayload?.data?.token);
        localStorage.setItem("id", codePayload?.data?.user?.id);
        // setImageData(codePayload);
        history.push("/");
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    }
  };

  const verify2FAHandler = (firstField, secondField, thirdField, fourthField, fifthField, sixthField) => {
    localStorage.setItem("signature", "1234567890");
    history.push("/");
  };

  const signUpHandler = async (firstName, lastName, email, companyName, contactNumber, categoryValue, countryCode) => {
    setLoading(true);
    let data = {
      email: email,
      email_confirmation: email,
      phone: contactNumber,
      role: parseInt(process.env.REACT_APP_MERCHANT_ROLE),
      category: categoryValue,
      bussiness_name: companyName,
      director_first_name: firstName,
      director_last_name: lastName,
      type: "web",
      country_code: countryCode,
    };
    try {
      const signUpPayload = await requestHandler("register", "post", data, "jwt_token");
      setLoading(false);
      if (signUpPayload.status === 200) {
        addNotification({
          title: "Success",
          message: signUpPayload?.data?.message,
          type: "success",
        });
        setUserId(signUpPayload?.data?.user?.id);
        setActiveIndex(1);
      };
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.error,
        type: "danger",
      });
    };
  };

  const resendOtpHandler = async () => {
    setLoading(true);
    let data = {
      email: email
    };
    try {
      const resendOtpPayload = await requestHandler("regenerate-otp", "post", data, "jwt_token");
      setLoading(false);
      if (resendOtpPayload.status === 200) {
        addNotification({
          title: "Success",
          message: resendOtpPayload?.data?.message,
          type: "success"
        });
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again later.",
        type: "danger"
      });
    };
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.wrap}>
          <div className={styles.preview}>
            <img src="/images/logo-light.svg" alt="Login" />
          </div>
          <div className={cn("h4", styles.subtitle)}>Business</div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li key={index}>{x}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <div className={styles.info}>
            Already a member?{" "}
            <Link className={styles.link} to="/sign-in">
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={cn("h2", styles.title)}>Sign Up</div>
          {loading && <LoaderScreen />}
          {activeIndex === 0 &&
            <Entry
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              businessType={businessType}
              setBusinessType={setBusinessType}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              companyName={companyName}
              setCompanyName={setCompanyName}
              userDataHandler={userDataHandler}
              countryCode={countryCode}
              setUserData={setUserData}
              categoryData={categoryData}
              categoryText={categoryText}
              setCategoryText={setCategoryText}
              setCategoryValue={setCategoryValue}
              categoryValue={categoryValue}
              signUpHandler={signUpHandler}
            />}
          {activeIndex === 1 && <Code
            setActiveIndex={setActiveIndex}
            codeHandler={codeHandler}
            resendOtpHandler={resendOtpHandler}
          />}
          {/* {activeIndex === 1 && <Residence
            setActiveIndex={setActiveIndex}
            signUpHandler={signUpHandler}
            userData={userData}
          />} */}
          {/* {activeIndex === 2 && <Code
            setActiveIndex={setActiveIndex}
            codeHandler={codeHandler}
          />}
          {activeIndex === 3 && <Barcode
            verify2FAHandler={verify2FAHandler}
            imageData={imageData}
          />} */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
