import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const Row = ({ item }) => {

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        {item?.from_user?.uid === null || item?.from_user?.uid === undefined ? "---" : item?.from_user?.uid}
      </div>
      <div className={styles.col}>
        {item?.to_user?.uid === undefined || item?.to_user?.uid === null ? "---" : item?.to_user?.uid}
      </div>
      <div className={styles.col}>
        SRD {getDigitsAfterDecimal(item?.amount, 2)}
      </div>
      <div className={styles.col}>
        {item?.type.toUpperCase()}
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.txId}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.cell}>
          <div className={styles.box}>
            <div className={styles.number}>
              {item?.payment_reference === null || item?.payment_reference === undefined ? "---" : item?.payment_reference}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        {
          parseInt(item?.status) === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
            <div className={cn("status-red-dark", styles.distribution)}>
              Pending
            </div>
            :
            parseInt(item?.status) === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED) ?
              <div className={cn("status-green-dark", styles.distribution)}>
                Approved
              </div>
              :
              parseInt(item?.status) === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Reject
                </div>
                :
                ""
        }
      </div>
      <div className={styles.col}>
        {toLocaleStringDateFormat(item?.created_at)}
      </div>
    </div>
  );
};

export default Row;
