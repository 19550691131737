import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import SimpleReactValidator from "simple-react-validator";

const KybData = ({
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  resetPasswordHandler
}) => {
  const [passwordType, setPasswordType] = useState("password");
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator(
    {
      messages: {
        in: "Password should be matched!"
      }
    }
  ));

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
        <TextInput
          className={styles.field}
          name="newPassword"
          type={passwordType}
          placeholder="Enter New Password"
          required
          icon="lock"
          value={newPassword}
          togglePassword={togglePassword}
          view
          onChange={(e) => { setNewPassword(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("newPassword")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("newPassword", newPassword, "required|min:9")}
        </span>
        <TextInput
          className={styles.field}
          name="confirmNewPassword"
          type="password"
          placeholder="Enter confirm password"
          required
          icon="lock"
          value={confirmNewPassword}
          onChange={(e) => { setConfirmNewPassword(e.target.value) }}
          onBlur={() => simpleValidator.current.showMessageFor("confirmNewPassword")}
        />
        <span className={styles.errorMessage}>
          {simpleValidator.current.message("confirmNewPassword", confirmNewPassword, `required|in:${newPassword}`)}
        </span>
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            resetPasswordHandler(newPassword, confirmNewPassword);
          } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
          }
        }}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default KybData;
