import React from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import CashRechargeDetails from "./CashRechargeDetails";
import CustomerListSkeleton from "../../../components/Skeleton/CutomerListSkeleton";

const RecentPost = ({
  className,
  loading,
  setVisibleModal,
  visibleModal,
  transactionData,
  setData,
  data,
  detailsModal,
  setDetailsModal
}) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent Transactions"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>From</div>
            <div className={styles.col}>To</div>
            <div className={styles.col}>Amount</div>
            <div className={styles.col}>Transaction Type</div>
            <div className={styles.col}>Transaction Id</div>
            <div className={styles.col}>Reference Number</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {loading ? <CustomerListSkeleton colCount={8} rowCount={12} />
            :
            transactionData?.length !== 0 ?
              transactionData?.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  setData={setData}
                  setDetailsModal={setDetailsModal}
                />
              ))
              :
              "No data found"
          }
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost
          loading={loading}
          transactionData={transactionData}
        />
      </Modal>
      <Modal
        outerClassName={styles.outer}
        visible={detailsModal}
        onClose={() => setDetailsModal(false)}
      >
        <CashRechargeDetails
          loading={loading}
          data={data}
        />
      </Modal>
    </>
  );
};

export default RecentPost;
