import React from "react";
import styles from "./NewPost.module.sass";
import cn from "classnames";
import { toLocaleStringDateFormat, getDigitsAfterDecimal } from "../../../../components/helper";

const NewPost = ({
  requestData
}) => {

  return (
    <div className={styles.share}>
      <div className={styles.head}>
        <div className={cn("title-red", styles.title)}>Request Details</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Name
        </div>
        <div className={styles.col}>
          {`${requestData?.users[0]?.first_name} ${requestData?.users[0]?.last_name}`}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          Amount
        </div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(requestData?.amount, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Fee</div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(requestData?.cashrecharge_fee?.cashrecharge_fee, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Amount Received</div>
        <div className={styles.col}>
          SRD {getDigitsAfterDecimal(requestData?.total_amount, 2)}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Status</div>
        <div className={styles.col}>
          <div className={styles.col}>
            {
              requestData?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_PENDING) ?
                <div className={cn("status-red-dark", styles.distribution)}>
                  Pending
                </div> :
                requestData?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_APPROVED) ?
                  <div className={cn("status-green-dark", styles.distribution)}>
                    Approved
                  </div> :
                  requestData?.status === parseInt(process.env.REACT_APP_CASH_RECHARGE_REQUEST_REJECT) ?
                    <div className={cn("status-red-dark", styles.distribution)}>
                      Declined
                    </div> : ""
            }
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>UId</div>
        <div className={styles.col}>
          {requestData?.users[0]?.uid}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Date</div>
        <div className={styles.col}>
          {toLocaleStringDateFormat(requestData?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default NewPost;
